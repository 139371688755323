import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import logo from "../assets/HDFC-Ergo-logo.png";
import { clientName } from "../config";
import DocumentAnalyzerImage from "./../assets/landing/document-analyzer.svg";
import CompetetiveAnalysisImage from "./../assets/landing/competitor-analysis.svg";
import productAnalysisImage from "./../assets/landing/product-analysis.svg";
import ctrlNLogo from "./../assets/teamlogo.svg";
import theme from "../theme";
import Profile from "../components/profile";
import UserAgreement from "../components/UserAgreement";

type CardItemProps = {
  image: string;
  title: string;
  description: string;
  link: string;
  buttonText: string;
};

const cardData = [
  {
    image: DocumentAnalyzerImage,
    title: "AI HR Bot",
    description: "Need to know about HR Policies",
    link: "/hr-bot",
    buttonText: "Explore",
  },
  {
    image: CompetetiveAnalysisImage,
    title: "AI Competitor Analysis",
    description: "Need to know about competitor analysis",
    link: "/competitor-analysis",
    buttonText: "Explore",
  },
  {
    image: productAnalysisImage,
    title: "AI Product Catalogue",
    description: "Need to know about Product Catalogue",
    link: "/product-catalog",
    buttonText: "Explore",
  },
];

const CardItem = ({
  image,
  title,
  description,
  link,
  buttonText,
}: CardItemProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item lg={4}>
      <Card
        elevation={8}
        sx={{
          borderRadius: "24px",
          transition: "all 200ms ease-in",
          "&:hover": {
            transform: isSmallScreen ? "translateY(0)" : "translateY(-16px)",
          },
        }}
      >
        <CardContent sx={{ padding: "20px", paddingBottom: 0 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={700}
            fontSize={20}
            // mb={1}
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="#4d4848"
            fontWeight={400}
            textAlign="center"
            fontSize={13}
          >
            {description}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt={title}
              height="260"
              style={{ width: "80%", margin: "auto" }}
            />
          </div>
        </CardContent>

        <CardActions
          sx={{
            padding: "8px 16px 16px 16px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Link to={link}>
            <Button
              sx={{
                color: "#171515",
                borderRadius: "60px",
                border: "2px solid #171515",
                fontSize: "14px",
                padding: "4px 24px",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#171515",
                },
              }}
            >
              {buttonText}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
};


export default function Landing() {

  return (
    <>
      <UserAgreement />

      <Box
        bgcolor={"hsla(215, 15%, 97%, 0.5)"}
        height={"100svh"}
        p={2}
        pb={2}
        overflow="auto"
      >
        <Profile />
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item md={9}>
            <Grid container spacing={2} justifyContent={"center"}>
              {/* Logo and Title Section */}
              <Grid item md={10}>
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  mb={1}
                // mt={4}
                >
                  <img
                    src={logo}
                    alt="HDFC Ergo Logo"
                    height={50}
                    style={{ objectFit: "contain" }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  align="center"
                  // mb={1}
                  fontWeight={700}
                  textTransform={"uppercase"}
                >
                  HDFC ERGO - {clientName}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={13}
                  color={"#4d4848"}
                >
                  A secure internal platform for employees to explore AI's
                  potential.
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  mb={3}
                  fontSize={13}
                  color={"#4d4848"}
                >
                  Get answers through human like conversations, generate quality
                  content and more.
                </Typography>
              </Grid>

              {/* Cards Section */}
              {cardData.map((card, index) => (
                <CardItem
                  key={card.title}
                  image={card.image}
                  title={card.title}
                  description={card.description}
                  link={card.link}
                  buttonText={card.buttonText}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box mt={2} sx={{ display: "grid", placeItems: "center" }}>
          <Typography
            textAlign="center"
            fontSize={12}
            color="#4d4848"
            py={1}
            px={2}
            borderRadius={20}
            fontWeight={500}
            sx={{ background: "#f5f5f5", width: "auto" }}
          >
            Note: Always verify the generated response
          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          pt={1}
        >
          <Typography fontWeight={500} mr={0.6} fontSize={13}>
            Powered by
          </Typography>
          <img src={ctrlNLogo} alt="ctrl n" height={40} />
        </Box>
      </Box>
    </>
  );
}
