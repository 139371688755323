import axios from "axios";
// import { baseURL } from "../config";
const baseURL = process.env.REACT_APP_BASE_URL;

export const uploadFile = async (
  vector_name: string,
  file_name: string,
  base64File: string
) => {
  const data = JSON.stringify({
    vector_name: vector_name,
    file_name: file_name,
    base64File: base64File,
  });

  const res = // { data: { result: query } };
    await axios({
      method: "post",
      url: `${baseURL}/doc-analyzer/upload-file`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  return res.data.status;
};

export const getFilesList = async (vector_name: string) => {
  const data = JSON.stringify({
    vector_name: vector_name,
  });

  const res = // { data: { result: query } };
    await axios({
      method: "post",
      url: `${baseURL}/doc-analyzer/list-files`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  return res.data.files;
};
