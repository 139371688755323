import { Avatar, Box } from "@mui/material";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import { useUserDetails } from "../App";
import { handleLogout } from "../utils/common";

function Profile() {
  const { userDetails, setUserDetails } = useUserDetails();

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      gap={1.5}
      sx={{ position: "relative", right: "15px", cursor: "pointer" }}
    >
      <LogoutTwoToneIcon sx={{ fontSize: 20 }} onClick={() => handleLogout(setUserDetails)} />
      <Avatar sx={{ width: 24, height: 24 }}>{userDetails?.name[0]}</Avatar>
    </Box>
  );
}

export default Profile;
