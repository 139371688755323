import { AutoAwesomeOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import RadioGroupRating from "./rating";
import { useSnackbar } from "notistack";

let questions = [
  "how helpful was this conversation?",
  "how likely would you suggest this to your colleagues?",
];

export default function Feedback() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState<number|null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return !isSubmitted && !isSmallScreen ?(
    <motion.div
      initial={{ y: 100 }}
      transition={{ duration: 1 }}
      whileInView={{ y: 0 }}
      style={{ zIndex: -1 }}
    >
        <Box p={3} borderRadius={1} bgcolor={"white"} display={'flex'} alignItems={'center'}>
          <Box>
            <IconButton>
              <AutoAwesomeOutlined />
            </IconButton>
          </Box>
          {questions.map((question, index) => <Box px={2}>
            <Typography variant="body2" mb={2}>
              {question}
            </Typography>
            <RadioGroupRating onChange={(rating) => {
                setRating(rating);
            }} />
          </Box>)}


          {/* <Box px={2} pb={2}>
            <Typography variant="body2" mb={2}>
              {question}
            </Typography>
            <RadioGroupRating onChange={(rating) => {
                setRating(rating);
            }} />
          </Box> */}

          {/* <Box
            py={2}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            {questions.map((question, index) => (
              <Box key={index} display={'flex'}>
                <Box
                  height={8}
                  width={8}
                  bgcolor={index === currentQuestionIndex ? "white":"primary.main"}
                  border={(theme) => `1px solid ${theme.palette.primary.main}`}
                  borderRadius={8}
                ></Box>
                <Box width={4}></Box>
              </Box>
            ))}
          </Box> */}
            <Box pl={2}>
            <Button variant="outlined" onClick={() => {
                if(rating !=null && rating >0){
                    setSubmitted(true);
                }else{
                  enqueueSnackbar('Please answer the questions');
                }
            }}>Submit</Button>
            </Box>
        </Box>
    </motion.div>
  ):null;
}
