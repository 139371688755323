import { AutoAwesomeOutlined } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { defaultLocale } from "../config";
import useChatbot from "../provider/chatbot_provider";

export default function SuggestionChips({
  gridSpace = 3,
  align,
  suggestions,
}: {
  gridSpace: number;
  align?: string;
  suggestions: string[];
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { addConversation } = useChatbot();

  return isSmallScreen ? (
    <Box
      px={align !== "flex-end" ? 0 : 1}
      sx={{
        overflow: "auto",
        width: (theme) =>
          align !== "flex-stretch"
            ? `calc(100svw - ${theme.spacing(5)})`
            : "100svw",
      }}
      display={"block"}
      flexDirection={"row"}
      whiteSpace={align !== "flex-end" ? "wrap" : "nowrap"}
    >
      {suggestions.map((suggestion, index) => (
        <Box
          px={0.5}
          py={isSmallScreen ? 0.5 : 0.2}
          width={isSmallScreen ? "22rem" : "auto"}
          display={"inline-block"}
          whiteSpace={"nowrap"}
          key={index}
        >
          <Chip
            label={suggestion}
            variant="outlined"
            onClick={() => addConversation(suggestion, defaultLocale)}
          />
        </Box>
      ))}
    </Box>
  ) : (
    <Grid container spacing={2} alignItems={align || "flex-start"}>
      {suggestions.map((suggestion, index) => (
        <Grid item md={gridSpace} key={index}>
          <motion.div
            initial={{ y: 100 }}
            transition={{ duration: 0.5 + index / 8 }}
            whileInView={{ y: 0 }}
            style={{ zIndex: -1 }}
          >
            <ButtonBase
              sx={{ borderRadius: "10px", textAlign: "left" }}
              onClick={() => addConversation(suggestion, defaultLocale)}
            >
              <Box
                sx={{ borderRadius: "10px", p: 1.5 }}
                bgcolor={"white"}
                display={"flex"}
                alignItems={"start"}
                gap={2}
              >
                <Box sx={{ scale: "0.9" }}>
                  <AutoAwesomeOutlined />
                </Box>
                <Typography
                  variant="body2"
                  title={suggestion}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    fontSize: "12px",
                  }}
                >
                  {suggestion}
                </Typography>
              </Box>
            </ButtonBase>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}
