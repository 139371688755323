import { createBrowserRouter, Navigate } from "react-router-dom";
import { ChatbotProvider } from "../provider/chatbot_provider";
import Chatbot from "../views/chatbot";
import Landing from "../views/landing";
import Login from "../views/login";
import PrivateRoute from "./privateRoute";
import ErrorBoundary from "./ErrorBoundary";
import PageNotFound from "../components/PageNotFound";
// import Test from "./views/test";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    hasErrorBoundary: true,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Landing />,
      },
      {
        path: "/hr-bot",
        element: (
          <ChatbotProvider tool={"hr"}>
            <Chatbot tool="HR Bot" />
          </ChatbotProvider>
        ),
      },
      // {
      //   path: "/document-analyzer",
      //   element: (
      //     <ChatbotProvider tool={"document"}>
      //       <Chatbot tool="Document Analyzer" />
      //     </ChatbotProvider>
      //   ),
      // },
      {
        path: "/competitor-analysis",
        element: (
          <ChatbotProvider tool={"competitor"}>
            <Chatbot tool="Competitor Analysis" />
          </ChatbotProvider>
        ),
      },
      {
        path: "/product-catalog",
        element: (
          <ChatbotProvider tool={"catalog"}>
            <Chatbot tool="Product Catalogue" />
          </ChatbotProvider>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  // {
  //   path: "/test",
  //   element: <Test />,
  // },
]);

export default router;
