import {
  // PictureAsPdfOutlined,
  Delete,
  AddRounded,
  BubbleChart,
  CloseSharp,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
// import { logoURL } from "../config";
import logo from "../assets/HDFC-Ergo-logo.png";
import useChatbot from "../provider/chatbot_provider";
import { useEffect } from "react";
import ctrlNLogo from "../assets/teamlogo.svg";
import { useUserDetails } from "../App";
import { getFirstName } from "../utils/common";

export default function ChatbotNavbar({
  isDrawerOpen,
  handleDrawer,
}: {
  tool: string;
  vectorName: string | undefined;
  handleOpen: any;
  isDrawerOpen: boolean;
  handleDrawer: (action: boolean) => void;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    chats,
    selectedChat,
    newChat,
    deleteChat,
    setSelectedChat,
    setSelectedQuery,
  } = useChatbot();

  const { userDetails } = useUserDetails();

  useEffect(() => {
    if (!isSmallScreen) handleDrawer(true);
  }, [isSmallScreen]);

  return (
    <Drawer
      sx={{
        mt: "100px",
        position: isSmallScreen ? "fixed" : "inherit",
        zIndex: isSmallScreen ? "999999" : "0",
        width: isSmallScreen ? "75vw" : 280,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isSmallScreen ? "75vw" : 280,
          boxSizing: "border-box",
        },
      }}
      variant={isDrawerOpen ? "persistent" : undefined}
      anchor="left"
      open={isDrawerOpen}
    >
      <Box
        // width={240}
        // boxShadow={1}
        bgcolor={"white"}
        display="flex"
        flexDirection="column"
        height={"100vh"}
      // borderRight="1px solid rgba(0,0,0,0.12)"
      >
        {!isSmallScreen ? (
          <Box p={3} pb={2}>
            <Link to="/dashboard" style={{ textDecoration: "none", color: "inherit" }} >
              <img
                src={logo}
                alt="logo"
                style={{ height: 55, width: "100%", objectFit: "contain" }}
              />
            </Link>
          </Box>
        ) : (
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            fontWeight="600"
          >
            Hello! {getFirstName(userDetails?.name)}
            <IconButton
              sx={{ float: "right" }}
              onClick={() => handleDrawer(false)}
            >
              {/* <ChevronLeft /> */}
              <CloseSharp />
            </IconButton>
          </Box>
        )}

        {/* <Box>
        <Typography variant="h6" align="center" sx={{ fontWeight: 600 }}>
          {tool}
        </Typography>
      </Box> */}
        {isSmallScreen && <Divider sx={{ marginBottom: 2 }} />}
        <Button
          endIcon={<AddRounded />}
          variant="contained"
          color="primary"
          sx={{
            p: "10px",
            mx: "16px",
            marginBottom: "8px",
            borderRadius: "8px",
            width: "unset",
            fontSize: "13px",
          }}
          onClick={newChat}
        >
          New chat
        </Button>

        {/* {tool === "Document Analyzer" && (
          <Button
            endIcon={<PictureAsPdfOutlined />}
            variant="contained"
            color="primary"
            sx={{
              p: "10px",
              mx: "16px",
              mt: "4kpx",
              marginBottom: "10px",
              borderRadius: "8px",
              width: "unset",
              fontSize: "13px",
            }}
            onClick={handleOpen}
          >
            Document Analyzer
          </Button>
        )} */}

        <Box
          flex={1}
          overflow={"scroll"}
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <Typography mx={2} variant="caption" sx={{ fontWeight: 600 }}>
            Recent
          </Typography>
          <List>
            {chats.map((chat, index) => (
              <ListItem
                button
                selected={selectedChat === index}
                key={index}
                onClick={() => {
                  setSelectedQuery(null);
                  setSelectedChat(index);
                }}
              >
                <ListItemIcon sx={{ minWidth: "44px" }}>
                  <BubbleChart />
                </ListItemIcon>
                <ListItemText
                  primary={
                    chat.conversations.length > 0
                      ? chat.conversations[0].query
                      : chat.title
                  }
                  primaryTypographyProps={{
                    textOverflow: "ellipsis",
                    noWrap: true,
                    fontSize: "13px",
                  }}
                />
                {(chat.conversations.length > 0 || index > 0) && (
                  <IconButton
                    size="small"
                    sx={{ float: "right" }}
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      deleteChat(e, chat?.sessionId)
                    }
                  >
                    <Delete color="primary" sx={{ fontSize: 20 }} />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>

          {/* <Box my={1}>
            <Divider />
          </Box> */}
          {/*
          <Typography mx={2} variant="caption" sx={{ fontWeight: 600 }}>
            CHAT HISTORY
          </Typography>
          <List>
            {last5Queries.map((queryObj: any, index: number) => (
              <ListItem
                button
                selected={selectedQuery === index}
                key={index}
                // onClick={() => promptQueryFromHistory(queryObj, index)}
                onClick={() => addConversationFromHistory(queryObj)}
              >
                <ListItemIcon sx={{ minWidth: "44px" }}>
                  <BubbleChart />
                </ListItemIcon>
                <CustomTooltip
                  title={JSON.parse(queryObj?.request)?.query}
                  placement="right"
                >
                  <ListItemText
                    primary={JSON.parse(queryObj?.request)?.query}
                    primaryTypographyProps={{
                      textOverflow: "ellipsis",
                      noWrap: true,
                      fontSize: "13px",
                    }}
                  />
                </CustomTooltip>
              </ListItem>
            ))}
          </List> */}

          {/* <Box my={1}>
            <Divider />
          </Box> */}
          {/* 
          <Typography mx={2} variant="caption" sx={{ fontWeight: 600 }}>
            Tools
          </Typography>
          <List>
            <NavItem
              href="/document-analyzer"
              label="Document Analyzer"
              pathname={pathname}
              icon={<DocumentScannerOutlined sx={{ fontSize: 22 }} />}
            />
            <NavItem
              href="/competitor-analysis"
              label="Competitor Analysis"
              pathname={pathname}
              icon={<AutoGraphOutlined sx={{ fontSize: 22 }} />}
            />
            <NavItem
              href="/product-catalog"
              label="Product Catalogue"
              pathname={pathname}
              icon={<DonutSmallOutlined sx={{ fontSize: 22 }} />}
            />
          </List>
        </Box>
        <Divider />
        <Box> */}
          {/* <List>
            <NavItem
              label="Help"
              icon={<HelpOutlineOutlined sx={{ fontSize: 22 }} />}
              isComingSoon
            />
            <NavItem
              label="Activity"
              icon={<RestoreOutlined sx={{ fontSize: 22 }} />}
              isComingSoon
              showBadge
            />
            <NavItem
              label="Settings"
              icon={<SettingsOutlined sx={{ fontSize: 22 }} />}
              isComingSoon
            />
          </List> */}
          {/* <Divider />*/}
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          py={1}
        >
          {/* <a href="https://niveussolutions.com/" target="_blank" rel="noreferrer">
          <img
            src={
              "https://gitlab.niveussolutions.com/uploads/-/system/appearance/header_logo/1/niveus_logo.png"
            }
            alt="Niveus Solutions"
            height={20}
          />
        </a> */}
          <Typography fontWeight={500} mr={0.6} fontSize={14}>
            Powered by
          </Typography>
          <img src={ctrlNLogo} alt="ctrl n" height={40} />
        </Box>
      </Box>
    </Drawer>
  );
}

type NavItemProps = {
  pathname?: string;
  href?: string;
  label: string;
  icon: React.ReactNode;
  isComingSoon?: boolean;
  showBadge?: boolean;
  onClick?: () => void;
};

export function NavItem({
  pathname,
  href,
  label,
  icon,
  isComingSoon = false,
  showBadge = false,
  onClick,
}: NavItemProps) {
  const { enqueueSnackbar } = useSnackbar();

  function comingSoon() {
    enqueueSnackbar("Coming soon.");
  }

  if (isComingSoon) {
    return (
      <ListItem button selected={false} onClick={comingSoon}>
        <ListItemIcon sx={{ minWidth: "40px" }}>
          {showBadge ? (
            <Badge
              color="primary"
              overlap="circular"
              badgeContent=" "
              variant="dot"
            >
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ fontSize: "13px" }}
        />
      </ListItem>
    );
  }

  return (
    <Link
      to={href || "#"}
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={onClick}
    >
      <ListItem
        selected={pathname === href}
        sx={{
          "&:hover": {
            backgroundColor: "#f5f5f5",
            color: "#000",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          {showBadge ? (
            <Badge
              color="primary"
              overlap="circular"
              badgeContent=" "
              variant="dot"
            >
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ fontSize: "13px" }}
        />
      </ListItem>
    </Link>
  );
}
