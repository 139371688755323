import { decrypt } from "./encryption";

export const getFirstName = (name: string) => {
  return name.split(" ")[0];
};


export const handleLogin = (response: any, setUserDetails: any) => {
  const encryptedData = response?.data?.data;
  localStorage.setItem("userDetails", encryptedData);
  setUserDetails({ ...JSON.parse(decrypt(encryptedData)) });
}


export const handleLogout = (setUserDetails: any) => {
  setUserDetails({
    userId: "",
    name: "",
    username: "",
    accessToken: "",
  });
  localStorage.removeItem("userDetails");
  localStorage.clear();
};
