import axios, { AxiosInstance } from "axios";
import { decrypt } from "./encryption";
const baseURL = process.env.REACT_APP_BASE_URL;

const createAxiosInstance = (endpoint: string = "") => {
  const newInstance = axios.create({ baseURL: `${baseURL}/${endpoint}` });

  newInstance.interceptors.request.use(function (request) {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      let token = JSON.parse(decrypt(userDetails || ""))?.accessToken;
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  return newInstance;
};

const hrClient = createAxiosInstance("hr-bot");

const documentClient = createAxiosInstance("doc-analyzer");

const competitorClient = createAxiosInstance("competitor-analysis");

const productClient = createAxiosInstance("product-catalogue");

const baseClient = createAxiosInstance();

export const tools: Record<string, AxiosInstance> = {
  hr: hrClient,
  competitor: competitorClient,
  catalog: productClient,
  document: documentClient,
  default: baseClient,
};

export const getClient = (tool: string = "default") => {
  return tools[tool];
};
