import { createContext, useContext, useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
import { detectOS } from "./utils/osDetection";
import { userTypes } from "./types/user";
import "./assets/css/global.css";
import { decrypt } from "./utils/encryption";

const UserContext = createContext<any | null>(null);

export function useUserDetails(): any {
  let context = useContext(UserContext);
  return context!;
}

const getUser = () => {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    return JSON.parse(decrypt(userDetails));
  } else {
    return { name: "", username: "", accessToken: "" };
  }
};

export default function App() {
  const [userDetails, setUserDetails] = useState<userTypes>(getUser());

  useEffect(() => {
    const osClass = detectOS();
    document.body.classList.add(osClass);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <SnackbarProvider>
          <UserContext.Provider value={{ userDetails, setUserDetails }}>
            <RouterProvider router={router} />
          </UserContext.Provider>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}
