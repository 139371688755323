import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { ReactElement } from 'react';

interface CommonDialogProps {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  title: string;
}

export default function CommonDialog(
  { children,
    open,
    onClose,
    title
  }: CommonDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontWeight={700}>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}
