import React, { useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import leftSideImage from "../assets/loginLeftSide.png";
import logo from "../assets/HDFC-Ergo-logo.png";
import CommonInput from "../components/CommonInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { encrypt } from "../utils/encryption";
import { Form, Navigate } from "react-router-dom";
import { useUserDetails } from "../App";
import ctrlNLogo from "../assets/teamlogo.svg";
import { getClient } from "../utils/http";
import { handleLogin } from "../utils/common";

const Login: React.FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    userDetails: { accessToken: isAuthenticated },
    setUserDetails,
  } = useUserDetails();

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  // Email validation function

  function validate(email: string): boolean {
    const reg: RegExp =
      /^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  const onClickHandler = () => {
    setShowPassword((prev: boolean) => !prev);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event?.target?.value?.trim());
    setErrorMessage("");
  };

  const onChangePasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordValue(event?.target?.value?.trim());
    setErrorMessage("");
  };

  const onLoginHandler = () => {
    if (!userName?.length) {
      return setErrorMessage("Please enter Username or Email!");
    } else if (!passwordValue?.length) {
      return setErrorMessage("Please enter password!");
    }
    let data = {
      email: validate(userName)
        ? userName
        : userName + process.env.REACT_APP_USERNAME_EXT,
      password: passwordValue,
    };

    getClient()
      .post("/cxo-dashboard/auth/login", {
        data: encrypt(JSON.stringify(data)),
      })
      .then((response) => {
        handleLogin(response, setUserDetails)
      })
      .catch((error) => {
        setErrorMessage(
          error?.response?.data?.message || "Provide valid details"
        );
      });
  };

  // Detect Enter key press to trigger login
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onLoginHandler();
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
          padding: "1rem",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <img src={logo} alt="logo" width="100px" height="70px" />
      </Box>

      {!isSM && (
        <Grid item md={6} sm={12} xs={12}>
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <img
              src={leftSideImage}
              alt="leftSideImage"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
      )}

      <Grid item md={6} sm={12} xs={12} sx={{ background: "#f9eaeb" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: isSM ? "auto" : "80vh",
            paddingTop: isSM ? "0rem" : "4rem",
            marginTop: isSM ? "7rem" : "0rem",
          }}
        >
          <h1
            style={{
              margin: "auto auto 0 auto",
              fontSize: "1.875rem",
              fontWeight: "700",
              letterSpacing: "1px",
              textAlign: "center",
            }}
          >
            WELCOME!
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: "1.125rem",
              fontWeight: "400",
            }}
          >
            Please sign-in to your account and explore.
          </p>
          <Box
            sx={{
              padding: "32px 32px 24px 32px",
              width: isSM ? "95%" : "70%",
              margin: "1.2rem auto auto auto",
              borderRadius: "20px",
              boxShadow: "0px 0px 60px 0px #7777771A",
              background: "white",
            }}
            onKeyDown={handleKeyPress}
          >
            <Form>
              <CommonInput
                id="username"
                label="Username"
                placeholder="Eg. johndoe@email.com"
                onChangeHandler={onChangeHandler}
                value={userName}
              />
              <CommonInput
                id="password"
                label="Password"
                password
                passwordIconOff={<VisibilityOff />}
                passwordIcon={<Visibility />}
                onClickHandler={onClickHandler}
                showPassword={showPassword}
                placeholder="Enter your password"
                onChangeHandler={onChangePasswordHandler}
                value={passwordValue}
              />
              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ mb: 1 }}>
                  {errorMessage}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <a
                  style={{ color: "#2161DD" }}
                  rel="noreferrer"
                  href="https://pssm.hdfcergo.com/authorization.do"
                  target="_blank"
                >
                  Forgot Password?
                </a>
              </Box>
              <Button
                variant="contained"
                sx={{
                  background: "#252E3D",
                  width: "100%",
                  borderRadius: "5px",
                  fontSize: "0.8rem ",
                  fontWeight: "600",
                  padding: "10px 16px",
                  marginTop: "1.5rem",
                }}
                onClick={onLoginHandler}
              >
                Sign In
              </Button>
            </Form>
            <p style={{ textAlign: "center", marginTop: "1rem" }}>
              <span style={{ color: "#ADAEB0" }}>
                By signing in, you agree to the
              </span>
              &nbsp;
              <a
                style={{ color: "#2161DD" }}
                rel="noreferrer"
                href="https://www.hdfcergo.com/terms-conditions/terms"
                target="_blank"
              >
                terms and conditions.
              </a>
            </p>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography fontWeight={500} mr={0.6} fontSize={14}>
                Powered by
              </Typography>
              <img src={ctrlNLogo} alt="ctrl n" height={40} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
