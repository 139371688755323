import * as React from "react";
import { useState,useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getFilesList, uploadFile } from "../provider/query-api";
import SendIcon from "@mui/icons-material/Send";

export default function ManageDocs({
  setVectorName,
  vectorName,
  handleClose,
}: any) {
  const [vectorNameText, setVectorNameText] = useState("");
  const [fileUploadList, setFileUploadList] = useState([] as any);
  const [filesList, setFilesList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState("");

  const refreshFileList = () => {
    if (vectorName)
      getFilesList(vectorName).then((files) => {
        setFilesList(files);
      });
  };

 useEffect(() => {
    refreshFileList();
  }, [vectorName]);

  async function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const handleFileUpload = async () => {
    setUploadLoading(true);
    /* const base64FileList = */ await Promise.all(
      Array(fileUploadList.length)
        .fill(1)
        .map(async (fileUploadVal: any, index: number) => {
          const fileUploadListBase64 = await getBase64(fileUploadList[index]);
          const uploadStatus = await uploadFile(
            vectorName,
            fileUploadList[index].name,
            fileUploadListBase64 + ""
          );
          return uploadStatus;
        })
    );

    setFileUploadList([]);
    refreshFileList();
    setUploadLoading(false);
  };

  const handleSubmit = () => {
    if (vectorNameText.trim() === "") {
      setError("Please enter a profile name.");
    } else {
      setError("");
      setVectorName(vectorNameText);
      handleClose();
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      {!vectorName && (
        <Box sx={{ width: "auto" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={1.5}
          >
            Enter Profile Name
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              borderRadius: "10px !important",
            }}
            id="testing"
          >
            <TextField
              error={!!error}
              id="outlined-error-helper-text"
              sx={{
                borderRadius: "2px !important",
                fontSize: 13,
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
              // label={error ? "Error" : "Profile Name"}
              value={vectorNameText}
              placeholder="Profile Name"
              helperText={error || " "}
              onChange={(e) => {
                setVectorNameText(e.target.value);
                if (error) {
                  setError("");
                }
              }}
            />
            <Button
              variant="contained"
              // variant="text"
              color="primary"
              sx={{
                p: 2,
                width: "unset",
                borderRadius: "12px",
                height: "53px",
              }}
              onClick={handleSubmit}
              endIcon={<SendIcon />}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
      {vectorName && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Documents
            </Typography>
            {/* <TextField
            variant="standard"
            type="file"
          /> */}
            {/* <input type="file" multiple onChange={(e) => { console.log(e.target.files?.item(1)?.name) }}  /> */}
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Select files
              <VisuallyHiddenInput
                type="file"
                accept="application/pdf"
                multiple
                onChange={(e: any) => {
                  // check if each file size is less than 2 MB
                  const fileList = Array(e.target.files.length)
                    .fill(1)
                    .map((fileVal: any, index: number) => {
                      if (e.target.files.item(index)?.size > 2000000) {
                        alert(
                          `File ${
                            e.target.files.item(index)?.name
                          } is too large`
                        );
                        return null;
                      }
                      return e.target.files.item(index);
                    })
                    .filter((file: any) => {
                      return file;
                    });

                  if (fileList.length > 0) {
                    setFileUploadList(fileList);
                  }
                  // setFileUploadList(e.target.files)
                }}
              />{" "}
              (Max. 2 MB)
            </Button>
          </Box>

          <List dense={true}>
            {Array(fileUploadList.length)
              .fill(1)
              .map((fileUploadVal: any, index: number) => {
                return (
                  <ListItem>
                    <ListItemText primary={fileUploadList[index]?.name} />
                  </ListItem>
                );
              })}
          </List>

          {fileUploadList.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{ p: 2, width: "unset", mb: 2 }}
              onClick={() => {
                handleFileUpload();
              }}
              disabled={uploadLoading}
            >
              {uploadLoading ? "Loading..." : "Submit Upload"}
            </Button>
          )}

          <Divider />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Document Analyzer
          </Typography>
          <FormGroup>
            {filesList &&
              filesList.map((file) => {
                return (
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={file}
                    sx={{ width: "100%" }}
                  />
                );
              })}
          </FormGroup>

          <Divider />

          <Button
            variant="text"
            color="primary"
            sx={{ p: 2, mt: 2, width: "unset" }}
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </>
      )}
    </>
  );
}
