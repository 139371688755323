import { Outlet, Navigate } from "react-router-dom";
import { useUserDetails } from "../App";

const PrivateRoute = () => {
  const {
    userDetails: { accessToken: isAuthenticated },
  } = useUserDetails();
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/login" />}</>;
};

export default PrivateRoute;
