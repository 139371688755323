import React, { useState } from 'react'
import CommonDialog from './CommonDialog';
import { Box, Button, Typography } from '@mui/material';
import { handleLogout } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import { useUserDetails } from '../App';
import { messages } from '../utils/messages';


const gethowUserAgreementDialogStatus = () => {
    const status = localStorage.getItem("agreementDialogShown");
    if (!status) {
        return true;
    }
}

export default function UserAgreement() {
    const [showUserAgreementDialog, setShowUserAgreementDialog] = useState(gethowUserAgreementDialogStatus());
    const [readMore, setReadMore] = useState(false);
    const navigate = useNavigate();
    const { setUserDetails } = useUserDetails();


    const handleAgree = () => {
        setShowUserAgreementDialog(false);
        localStorage.setItem("agreementDialogShown", JSON.stringify(true));
    }

    const handleDisagree = () => {
        setShowUserAgreementDialog(false);
        handleLogout(setUserDetails);
        navigate("/login");
    }

    return (
        <>
            {showUserAgreementDialog && (
                <CommonDialog
                    open={showUserAgreementDialog}
                    onClose={() => { }}
                    title="User Agreement"
                >
                    <>
                        <Typography fontSize={14} mt={"-5px"}>
                            {readMore ? messages?.userAgreementFull : messages?.userAgreementTruncated}
                        </Typography>
                        <Button
                            variant="text"
                            onClick={() => setReadMore((prev) => !prev)}
                            sx={{ ml: -1 }}
                        >
                            {readMore ? 'Show Less' : 'Read More'}
                        </Button>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            gap={1}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAgree}
                            >
                                Agree
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleDisagree}
                            >
                                Disagree
                            </Button>
                        </Box>
                    </>
                </CommonDialog>
            )}
        </>
    )
}
