import forge from "node-forge";
import { v4 } from "uuid";

let key = decodeURIComponent(process.env.REACT_APP_ENCRYPTION_KEY!);

export function generateUUID(length = 36) {
  const uuidV = v4().slice(0, length);
  return uuidV;
}

export function encrypt(text: string) {
  let iv = generateUUID(16);
  let cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(text, "utf8"));
  cipher.finish();
  let encrypted = cipher.output;
  let d = forge.util.encode64(encrypted.data);
  return `${d}|${iv}`;
}

export function decrypt(encryptedText: string) {
  const splittedText = encryptedText?.split("|");
  const [encData, iv] = [splittedText?.[0], splittedText?.[1]];
  let decipher = forge.cipher.createDecipher("AES-CBC", key);
  decipher.start({ iv: iv });
  decipher.update(forge.util.createBuffer(forge.util.decode64(encData)));
  let result = decipher.finish();
  if (result) {
    return decipher.output.data;
  } else {
    throw new Error("Could not decrypt");
  }
}
