import { Box, useMediaQuery, useTheme } from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type Row = {
  id: number;
  key: string;
  [key: string]: string | number;
};

const StyledDataGrid = {
  "& .MuiDataGrid-columnHeaders": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    "--height": "auto !important",
    maxHeight: "unset !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "13px",
    whiteSpace: "normal",
    wordBreak: "break-word",
    padding: "15px",
    "&:nth-child(2)": {
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
};

export default function Graphs({ type, data }: { type: string; data: any }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function dataTable() {
    const columns: GridColDef<(typeof rows)[number]>[] = Object.keys(
      data[0]
    ).map((key: any, index) => {
      return {
        minWidth: 200,
        field: key,
        headerName: key.replace(/_/gi, " ").capitalize(),
        type:
          typeof data[0][key] === "number" && key !== "Year"
            ? "number"
            : "string",
        valueFormatter: key === "Year"
            ? (params: any) => params.value.toString()
            : (params: any) => params.value,
      };
    });

    const rows = data.map((row: any, index: number) => {
      const newRow = { ...row, id: index };
      Object.keys(newRow).forEach((key) => {
        if (newRow[key] === null || newRow[key] === undefined) {
          newRow[key] = "Data Not Available";
        }
      });
      return newRow;
    });

    return (
      <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
        display="flex"
        padding={1}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        margin="auto"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          getRowId={(row) => row.id}
          pageSizeOptions={[5, 10, 50, 100]}
          // checkboxSelection
          // disableRowSelectionOnClick
          sx={{

          }}
        />
      </Box>
    );
  }

  function compareTable() {
    const keys = Object.keys(data[0]);
    const rows: Row[] = keys.map((key, index) => {
      const row: Row = { id: index, key };
      data.forEach((item: any, itemIndex: number) => {
        row[`Value${itemIndex}`] = item[key] || "Data Not Available";
      });
      return row;
    });

    const columns: GridColDef[] = [
      { field: "key", headerName: "Key", width: 200 },
      ...data.map((_: any, index: any) => ({
        field: `Value${index}`,
        headerName: `Value ${index + 1}`,
        width: 300,
      })),
    ];

    return (
      <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
        display="flex"
        padding={1}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        margin="auto"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
          sx={StyledDataGrid}
          disableRowSelectionOnClick
        />
      </Box>
    );
  }

  function linechart() {
    let series: any = [];
    let xAxis: any = [];

    const firstEntry = data[0];
    const keys = Object.keys(firstEntry);
    series = keys
      .filter((key) => key !== "year" && typeof firstEntry[key] === "number")
      .map((key) => ({
        dataKey: key,
        label: key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()),
      }));

    const stringKeys = Object.keys(data[0]).filter(
      (key) => typeof data[0][key] === "string"
    );
    let uniqueValuesKey = stringKeys[0];

    // Check if the first string key has different values across all objects
    const allValues = data.map((item: any) => item[stringKeys[0]]);
    const uniqueValues = new Set<string>(allValues);
    if (uniqueValues.size === 1 && stringKeys.length > 1) {
      // If all values are the same and there's another string key, use the second key
      uniqueValuesKey = stringKeys[1];
    }

    xAxis = [
      {
        scaleType: "band",
        data: data.map((item: any) => {
          if (uniqueValuesKey === "month") {
            if ("year" in item) {
              return `${item.month.slice(0, 3)} \n${item.year}`;
            } else {
              return item.month.slice(0, 3);
            }
          } else {
            return item[uniqueValuesKey];
          }
        }),
      },
    ];
    return (
      <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
        display="flex"
        padding={1}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        margin="auto"
      >
        <LineChart
          dataset={data}
          width={isSmallScreen ? window.innerWidth / 1.5 : 800}
          height={isSmallScreen ? window.innerWidth / 1.5 : 400}
          series={series}
          xAxis={xAxis}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              padding: 45,
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </Box>
    );
  }

  function barchart() {
    let isGroupByYear = Object.keys(data[0]).includes("Year");

    if (isGroupByYear) {
      let groupedData = data.reduce((acc: any, x: any) => {
        acc[x["Year"]] = acc[x["Year"]] || [];
        acc[x["Year"]].push(x);

        return acc;
      }, {});

      let dataset = Object.keys(groupedData).reduce((acc: any, year) => {
        acc.push({
          year,
          ...groupedData[year].reduce((acc: any, x: any) => {
            let valueKey: any = Object.keys(x).find(
              (key) => typeof x[key] === "number" && key !== "Year"
            );

            let labelKey: any = Object.keys(x).find(
              (key) => typeof x[key] === "string" && key !== "Year"
            );

            acc[x[labelKey]] = x[valueKey];

            return acc;
          }, {}),
        });

        return acc;
      }, []);

      return (
        <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
          display="flex"
          padding={1}
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          margin="auto"
        >
          <BarChart
            width={isSmallScreen ? window.innerWidth / 1.5 : 600}
            height={isSmallScreen ? window.innerWidth / 1.5 : 300}
            margin={{ left: 100 }}
            dataset={dataset}
            xAxis={[{ scaleType: "band", dataKey: "year" }]}
            series={Object.keys(dataset[0] || {})
              .filter((key) => key !== "year")
              .map((key) => ({ dataKey: key, label: key }))}
          />
        </Box>
      );
      // } else if(isGroupByMonth) {
      //   let series: any = [];
      //   let xAxis: any = [];
      //   series = [
      //     {
      //       dataKey: 'fire',
      //       label: data[0].company_name
      //     }
      //   ];

      //   xAxis = [
      //     {
      //       scaleType: "band",
      //       data: data.map((item: any) =>  {
      //         return `${item.month.slice(0, 3)} \n${item.year}`
      //       }),
      //     },
      //   ];

      // series = [
      //   {
      //     data: data.map((item: any) => {
      //       let valueKey = Object.keys(item).find(
      //         (key) => typeof item[key] === "number"
      //       );
      //       return item[valueKey!];
      //     }),
      //     type: "bar",
      //   },
      // ];
      // xAxis = [
      //   {
      //     scaleType: "band",
      //     data: data.map((item: any) => {
      //       let labelKey = Object.keys(item).find(
      //         (key) => typeof item[key] === "string"
      //       );
      //       return item[labelKey!];
      //     }),
      //   },
      // ];

      //   return (
      //     <BarChart
      //       dataset={data}
      //       xAxis={xAxis}
      //       series={series}
      //       width={isSmallScreen ? window.innerWidth / 1.5 : 750}
      //       height={isSmallScreen ? window.innerWidth / 1.5 : 300}
      //     />
      //   );
    } else {
      let series: any = [];
      let xAxis: any = [];

      const firstEntry = data[0];
      const keys = Object.keys(firstEntry);
      series = keys
        .filter((key) => key !== "year" && typeof firstEntry[key] === "number")
        .map((key) => ({
          dataKey: key,
          label: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
        }));

      const stringKeys = Object.keys(data[0]).filter(
        (key) => typeof data[0][key] === "string"
      );
      let uniqueValuesKey = stringKeys[0];

      // Check if the first string key has different values across all objects
      const allValues = data.map((item: any) => item[stringKeys[0]]);
      const uniqueValues = new Set<string>(allValues);
      if (uniqueValues.size === 1 && stringKeys.length > 1) {
        // If all values are the same and there's another string key, use the second key
        uniqueValuesKey = stringKeys[1];
      }

      xAxis = [
        {
          scaleType: "band",
          data: data.map((item: any) => {
            if (uniqueValuesKey === "month") {
              if ("year" in item) {
                return `${item.month.slice(0, 3)} \n${item.year}`;
              } else {
                return item.month.slice(0, 3);
              }
            } else {
              return item[uniqueValuesKey];
            }
          }),
        },
      ];

      return (
        <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
          display="flex"
          padding={1}
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          margin="auto"
        >
          <BarChart
            dataset={data}
            width={isSmallScreen ? window.innerWidth / 1.5 : 800}
            height={isSmallScreen ? window.innerWidth / 1.5 : 400}
            series={series}
            xAxis={xAxis}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "top", horizontal: "right" },
                padding: 45,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                labelStyle: {
                  fontSize: 12,
                },
              },
            }}
          />
        </Box>
      );
    }
  }

  function piechart() {
    return (
      <>
        <PieChart
          series={[
            {
              data: data.map((item: any, index: any) => {
                let valueKey = Object.keys(item).find(
                  (key) => typeof item[key] === "number"
                );
                let labelKey = Object.keys(item).find(
                  (key) => typeof item[key] === "string"
                );
                return {
                  id: index,
                  value: item[valueKey!],
                  label: item[labelKey!],
                };
              }),
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 0, additionalRadius: 0, color: "gray" },
            },
          ]}
          width={isSmallScreen ? window.innerWidth / 1.5 : 400}
          height={isSmallScreen ? window.innerWidth / 1.5 : 300}
          // margin={{ top: 10, bottom: 10, left: 10, right: 10 }} // Increased bottom margin
          slotProps={{ legend: { hidden: true } }} // Hide the legend
        />
      </>
    );
  }

  return (
    <Box mt={isSmallScreen ? 2 : 4} maxWidth={"80vw"} overflow={"auto"}
      display="flex"
      padding={1}
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      margin="auto"
    >
      {type === "bar chart" ? barchart() : null}
      {type === "pie chart" ? piechart() : null}
      {type === "line chart" ? linechart() : null}
      {type === "data table" ? dataTable() : null}
      {type === "compare table" ? compareTable() : null}
    </Box>
  );
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});
