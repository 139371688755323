import {
  DownloadRounded,
  Person,
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt,
  VolumeUpRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createRef } from "react";
import Markdown from "react-markdown";
import { IConversation } from "../types/chatbot";
import Graphs from "./graphs";
import StarIcon from "./star_icon";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";

export default function Conversation({
  conversation,
  like,
  dislike,
}: {
  conversation: IConversation;
  like: () => void;
  dislike: () => void;
}) {
  const ref = createRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const urlArray =
    conversation?.url?.split(",").map((url) => url.trim()) || null;

  function speak(message: string) {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    } else {
      var speech = new SpeechSynthesisUtterance();
      speech.text = message;
      window.speechSynthesis.speak(speech);
    }
  }

  function download() {
    try {
      (window as any)
        .html2pdf()
        .set({ filename: `conversation.pdf` })
        .from(ref.current)
        .save();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box mb={isSmallScreen ? 2 : 4} ref={ref} p={isSmallScreen ? 1 : 0}>
      <Box px={isSmallScreen ? 0 : 2} marginBottom={2}>
        <List disablePadding>
          <ListItem>
            <ListItemAvatar sx={{ minWidth: 48 }}>
              <Avatar sx={{ width: 32, height: 32 }}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={conversation.query}
              primaryTypographyProps={
                isSmallScreen
                  ? { fontSize: 13, fontWeight: 500 }
                  : { fontSize: 15, fontWeight: 500 }
              }
            ></ListItemText>
          </ListItem>
        </List>
      </Box>

      <Box
        ml={isSmallScreen ? 1 : 2.5}
        pt={isSmallScreen ? 0 : 1}
        width={isSmallScreen ? "auto" : "46rem"}
        display={"flex"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        borderRadius={2}
        bgcolor={"#fff"}
        p={2}
      >
        <Box>
          <StarIcon isLoading={conversation.isLoading} />
        </Box>
        <Box
          flex={1}
          pr={1}
          pl={isSmallScreen ? 0 : 1}
          sx={{ maxWidth: "100%" }}
        >
          {conversation.isLoading ? (
            <Box pl={1} sx={{ marginTop: "-2px" }}>
              <Skeleton height={32} width={"100%"} />
            </Box>
          ) : (
            <Box
              sx={
                isSmallScreen
                  ? { fontSize: 14, padding: 0 }
                  : { maxWidth: "100%", overflow: "auto", fontSize: 16 }
              }
              // dangerouslySetInnerHTML={{
              //   __html: (conversation.result || "").replace(/\n/ig,"<br />"),
              // }}
            >
              {/* <Typewriter text="Hello" /> */}

              <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ node, ...props }) => (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                      {props.children}
                    </a>
                  ),
                }}
              >
                {conversation.result ||
                  "Sorry! Can you please rephrase your query?"}
              </Markdown>
              {urlArray &&
                urlArray.map(
                  (url, index) =>
                    url && (
                      <Typography sx={{ mt: 1, fontSize: 13 }} key={index}>
                        <Link to={url} target="_blank">
                          {url}
                        </Link>
                      </Typography>
                    )
                )}
            </Box>
          )}

          {!conversation.isLoading &&
            conversation.comparison &&
            conversation.comparison.length > 0 && (
              <Graphs type="compare table" data={conversation.comparison} />
            )}

          {!conversation.isLoading && conversation.isPlottable && (
            <Graphs
              type={conversation.displyType}
              data={conversation.message}
            />
          )}

          {!conversation.isLoading && (
            <Box pt={1} display={"flex"}>
              <IconButton
                onClick={() => speak(conversation.result!)}
                sx={{ width: 32, height: 32 }}
              >
                <VolumeUpRounded sx={{ fontSize: 18 }} />
              </IconButton>
              <IconButton onClick={like} sx={{ width: 32, height: 32 }}>
                {conversation.isLiked === true ? (
                  <ThumbUpAlt sx={{ fontSize: 18 }} />
                ) : (
                  <ThumbUpOffAlt sx={{ fontSize: 18 }} />
                )}
              </IconButton>
              <IconButton onClick={dislike} sx={{ width: 32, height: 32 }}>
                {conversation.isLiked === false ? (
                  <ThumbDownAlt sx={{ fontSize: 18 }} />
                ) : (
                  <ThumbDownOffAlt sx={{ fontSize: 18 }} />
                )}
              </IconButton>
              <IconButton onClick={download} sx={{ width: 32, height: 32 }}>
                <DownloadRounded sx={{ fontSize: 18 }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
