import { useEffect, useState, useCallback } from "react";
import { Box, Modal } from "@mui/material";
import Chat from "../components/chat";
import ChatbotNavbar from "../components/chatbot_navbar";
import ManageDocs from "../components/manage-docs";

export default function Chatbot({ tool }: { tool: string }) {
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [vectorName, setVectorName] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (vectorName) setOpen(false);
  };
  const handleDrawer = useCallback(
    (action: boolean) => setIsDrawerOpen(action),
    []
  );

  useEffect(() => {
    if (!vectorName && tool === "Document Analyzer") handleOpen();
  }, [tool, vectorName]);

  return (
    <div className="App">
      <Box display="flex" height="100svh">
        <ChatbotNavbar
          tool={tool}
          vectorName={vectorName}
          handleOpen={handleOpen}
          isDrawerOpen={isDrawerOpen}
          handleDrawer={handleDrawer}
        />
        <Box flex={1} bgcolor={"#f6f9f8"}>
          <Chat
            vectorName={vectorName}
            tool={tool}
            isDrawerOpen={isDrawerOpen}
            handleDrawer={handleDrawer}
            handleOpen={handleOpen}
          />
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              maxWidth: "95vw",
              maxHeight: "70vh",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 3,
              overflow: "hidden",
              overflowY: "auto",
              display: "block",
            }}
          >
            <ManageDocs
              vectorName={vectorName}
              setVectorName={setVectorName}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
